.TypesenseSync {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.TypesenseSync__status {
  display: flex;
  margin-left: 35px;
}

.TypesenseSync__button:hover > div > svg > path {
  fill: #0575e6;
}

.TypesenseSync__button:hover > .TypesenseSync__button-label {
  color: #0575e6;
  font-weight: 600;
}

.TypesenseSync__status-text {
  font-size: 13px;
  color: rgba(54, 54, 54, 0.8);
  margin-left: 11px;
}

.TypesenseSync__status-title {
  font-weight: 600;
  margin-bottom: 3px;
}

.TypesenseSync__status-subtitle {
  color: rgba(54, 54, 54, 0.6);
}

.TypesenseSync__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  margin-left: auto;
  width: 5.375rem;
  height: 4.125rem;
  border-left: 1px solid #d8d8d8;
  cursor: pointer;
  opacity: 0.7;
  transition: all 200ms ease-out;
}

.TypesenseSync__button-label {
  font-size: 0.6875rem;
  font-weight: bold;
  color: rgba(54, 54, 54, 0.4);
  margin-top: 4px;
  transition: all 200ms ease-out;
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.TypesenseSync__icon-animation {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
}
