.ConfirmModal {
  width: 500px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ConfirmModal__text {
  color: #363636;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
}
