.Import {
  height: calc(100% - 100px);
  width: 100%;
  background-color: #f2f2f2;
  overflow-y: auto;
  padding-bottom: 30px;
}

.Import__content {
  padding: 71px 124px 0 124px;
}

.Import__title {
  color: #363636;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 13px;
}

.Import__imageDrop {
  max-width: 622px;
}

.Import__failedUploads {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.Import__failedUploads-title {
  color: #363636;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 13px;
}
