.EditCategory {
  background-color: #e9e9e9;
}

.EditCategory__items {
  overflow-y: auto;
  max-height: calc(100% - 100px);
}

.EditCategory__title {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 35px;
  border-bottom: 1px solid #d8d8d8;
}

.EditCategory__title-text {
  color: #363636;
  font-weight: bold;
  font-size: 19px;
  padding-right: 16px;
}

.EditCategory__title-reset {
  height: 4.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding-inline: 16px;

  border-left: 1px solid #d8d8d8;
}

.EditCategory__title-reset > .text {
  font-size: 11px;
  font-weight: bold;
  opacity: 0.6;
  color: #363636;
  line-height: 16px;
  margin-top: 4px;

  white-space: nowrap;
}

.EditCategory__title-reset > .icon > .Icon {
  height: 20px;
}
