.Settings__wrapper {
  display: flex;
  height: 100%;
  margin-top: 100px;
}

.Settings__menu {
  flex: 2;
  border-right: 1px solid #d8d8d8;
  height: 100%;
  background-color: #e9e9e9;
  overflow-y: auto;
  min-height: 510px;
}

.Settings__content {
  flex: 5.5;
  height: 100%;
}

.Settings__toolbar {
  position: fixed;
  top: 0;
  left: 70px;
  background-color: #2a3040;
  z-index: 10;
  height: 100px;
  padding: 0 30px 0 50px;
  display: flex;
  align-items: center;
}

.Settings__toolbar-text {
  opacity: 0.6;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
