.SettingsListItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 2px;
  position: relative;
}

.SettingsListItem__block {
  display: grid;
  grid-template-columns: 1fr 1fr 50px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  font-size: 15px;
  color: #363636;
}

.SettingsListItem__name {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.SettingsListItem__label {
  display: flex;
  align-items: center;
  padding-left: 16px;
  opacity: .6;
}

.SettingsListItem__edit {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.SettingsListItem__delete {
  position: absolute;
  left: 100%;
  margin: 0 10px;
  cursor: pointer;
}