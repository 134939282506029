.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--button-color-text, #fff);
  background-color: var(--button-color-background, #000);
  border-radius: var(--button-border-radius, 0);
  font-size: var(--button-font-size, 0.9rem);
  font-weight: bold;
  transition: all 200ms ease-out;
}

/** Inactive state */
.Button--inactive {
  cursor: default;
  background-color: var(--button-color-background-inactive, #000);
}

/** Hover state */
.Button:not(.Button--inactive):hover {
  background-color: var(--button-color-background-hover, #000);
  color: var(--button-color-text-hover, #fff);
}

.Button--login {
  --button-border-radius: 5px;
  --button-color-background: #0575e6;
  --button-color-background-hover: #0575e6;
  --button-color-background-inactive: #0575e6;

  width: 174px;
  height: 35px;
}

.Button--save {
  --button-border-radius: 5px;
  --button-color-background: #0575e6;
  --button-color-background-hover: #0566c7;
  --button-color-background-inactive: rgb(101, 109, 117);
  --button-font-size: 15px;

  width: 100%;
  height: 100%;
}

.Button--editAdd {
  --button-border-radius: 5px;
  --button-color-background: #363636;
  --button-color-background-hover: #363636;
  --button-color-background-inactive: rgb(101, 109, 117);
  --button-font-size: 15px;

  height: 35px;
  width: 120px;
  margin-left: auto;
  margin-top: 10px;
}

.Button--syncAdd {
  --button-border-radius: 5px;
  --button-color-background: #0575e6;
  --button-color-background-hover: #0566c7;
  --button-color-background-inactive: rgb(101, 109, 117);
  --button-font-size: 15px;

  height: 35px;
  width: 167px;
  margin-left: auto;
  margin-top: 10px;
}

.Button--configureProperties {
  --button-border-radius: 5px;
  --button-color-text: rgba(54, 54, 54, 0.6);
  --button-color-text-hover: rgba(54, 54, 54, 0.9);
  --button-font-size: 12px;
  --button-color-background: transparent;
  --button-color-background-hover: #c1c1c1;

  border: 1px solid #c1c1c1;
  height: 25px;
  padding-left: 14px;
  padding-right: 14px;
}

.Button--configureProperties:hover {
  border-color: #c1c1c1;
}

.Button--primary {
  --button-border-radius: 5px;
  --button-color-background: #0575e6;
  --button-color-background-hover: #0566c7;
  --button-color-background-inactive: rgb(101, 109, 117);
  --button-font-size: 15px;

  height: 34px;
}

.Button--secondary {
  --button-border-radius: 5px;
  --button-color-text: rgba(54, 54, 54, 0.6);
  --button-color-text-hover: rgba(54, 54, 54, 0.9);
  --button-color-background: #e0e0e0;
  --button-color-background-hover: #b9b9b9;
  --button-color-background-inactive: rgb(101, 109, 117);
  --button-font-size: 15px;

  height: 34px;
}
