.SettingsMenu {
  height: 100%;
  position: relative;
}

.SettingsMenu__title {
  display: flex;
  align-items: center;
  height: 100px;
  padding-left: 35px;
  border-bottom: 1px solid #d8d8d8;
}

.SettingsMenu__title-text {
  color: #363636;
  font-size: 19px;
  font-weight: bold;
}

.SettingsMenu__link {
  height: 62px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  border-bottom: 1px solid #d8d8d8;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.SettingsMenu__link:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 200ms linear;
}

.SettingsMenu__activeblob {
  position: absolute;
  width: 4px;
  height: 20px;
  border-radius: 2px;
  background-color: #0575e6;
  margin: 20px 0;
  left: 12px;
  transition: top 200ms ease-in-out;
}
