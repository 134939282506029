.ProgressBar {
  width: 100%;
  height: 100%;
  background-color: #ececec;
  position: relative;
  /* z-index: 10; */
  border: 1px solid #CFCFCF;
  border-radius: 7px;
}

.ProgressBar__bar {
  /* background-color: #68CEA2; */
  height: 100%;
  display: block;
  border-radius: 7px 0 0 7px;
}

.ProgressBar__bar-text {
  position: absolute;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  width: 100%;
}