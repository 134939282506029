.TextArea {
  color: var(--form-color-text);
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  resize: none;
  transition: border var(--t-hover);
  font-family: var(--f-family);
}

.TextArea--default {
  border: 1px solid var(--form-color-border);
  border-radius: 5px;
  background-color: #fff;
  padding: 15px 19px 18px 19px;
  width: calc(100% - 38px);
  font-size: var(--form-font-size);
  outline: none;
}

.TextArea--default:not([disabled]):hover {
  border-color: var(--form-color-border-hover);
}

.TextArea--default:not([disabled]):focus-visible {
  border-color: var(--form-color-border-focus);
}

.TextArea[disabled] {
  background-color: var(--form-color-background-disabled);
  color: var(--form-color-text-disabled);
  cursor: default;
}
