.DropFiles {
  background-color: #fff;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  color: #0575e6;
}

.DropFiles__dropzone {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  cursor: pointer;
}

.DropFiles--dropActive {
  background-color: #0575e6;
  border: 1px solid #0575e6;
  color: #fff;
}

.DropFiles__icon {
  margin-right: 10px;
}

.DropFiles__text {
  line-height: 20px;
  font-size: 17px;
}

.DropFiles--theme-medium {
  height: 88px;
}

.DropFiles--theme-tall {
  height: 250px;
}

.DropFiles--theme-variant-upload {
  height: 250px;
  border: none;
}
