.EditVariants {
  --input-padding: 14px;
  --list-border-color: #d8d8d8;

  color: #363636;
}

.EditVariants__Header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.EditVariants__Headline {
  font-size: 15px;
  font-weight: 700;
}

.EditVariants__HeaderButtons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

/* [1] Reveal the drag and drop handle and delete buttons, keeping the x scrollbar visible */
.EditVariants__List {
  --overflow-margin: 20px;

  /* min-width: 100%; */
  margin-left: calc(var(--overflow-margin) * -1); /* [1] */
  margin-right: calc(var(--overflow-margin) * -1); /* [1] */
  padding-left: var(--overflow-margin); /* [1] */
  padding-right: var(--overflow-margin); /* [1] */
  overflow-x: auto;
}

/* 
 * [1] Ensure that the inner content of the Drag and Drop container wraps to the width of the inner elements 
 * [2] Make the element fill the width of the container on larger screens
 */
.EditVariants [data-rbd-droppable-id] {
  width: fit-content; /* [1] */
  min-width: 100%; /* [2] */
}

.EditVariants__ListHeader {
  display: flex;
  color: #363636;
  font-size: 11px;
  font-weight: 700;
  opacity: 0.6;
  margin-bottom: 2px;
}

.EditVariant__Image,
.EditVariants__ListHeaderImage {
  flex: none;
  width: 50px;
}

.EditVariant__Product,
.EditVariants__ListHeaderProduct {
  flex: none;
  width: 270px;
  padding-left: var(--input-padding);
  padding-right: var(--input-padding);
}

.EditVariant__Property,
.EditVariants__ListHeaderProperty {
  flex: none;
  width: 130px;
}

.EditVariants__ListHeaderProperty {
  padding-left: var(--input-padding);
  padding-right: var(--input-padding);
}

/* Push properties to the right, if there is space */
.EditVariants__ListHeaderProperty--index-0,
.EditVariant__Property--index-0 {
  margin-left: auto;
}

.EditVariant {
  font-size: 15px;
  height: 50px;
  border: 1px solid var(--list-border-color);
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  margin-bottom: 2px;
  position: relative;
  color: #363636;
  width: 100%;
}

.EditVariant__Product,
.EditVariant__Property {
  border-left: 1px solid var(--list-border-color);
}

.EditVariant__Image,
.EditVariant__Property {
  display: flex;
  align-items: center;
}

.EditVariant__Product {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  flex: auto;

  /* Fix for elipsis in flexbox */
  min-width: 0;
}

.EditVariant__ProductName {
  /* Add ellipsis to overflow text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Fix for elipsis in flexbox */
  width: 100%;
}

.EditVariant__ProductDescription {
  font-size: 11px;
  opacity: 0.6;
}

.EditVariant__Image {
  justify-content: center;
  background-color: var(--file-background);
}

.EditVariant__ImagePreview {
  flex: none;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.EditVariant__ImagePreview > img {
  max-height: 40px;
  max-width: 40px;
}

.EditVariant__AddImage {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: background var(--t-hover);
}

.EditVariant__AddImage:hover {
  background-color: var(--form-color-item-hover);
}

.EditVariantNull {
  height: 30px;
  border: 1px solid var(--list-border-color);
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  font-size: 12px;
}

/* Select styles */
.EditVariant__Property {
  position: relative;
}

.EditVariant__PropertySelector {
  /** Resete select input styles */
  appearance: none;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding-left: var(--input-padding);
  padding-right: var(--input-padding);
  cursor: pointer;
  color: inherit;
  font-size: inherit;
}

.EditVariant__PropertySelector:focus {
  outline: none;
}

.EditVariant__PropertySelectorIcon {
  position: absolute;
  top: 50%;
  right: var(--input-padding);
  transform: translateY(-50%);
  pointer-events: none;
}
