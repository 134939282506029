.OverviewCategories {
  position: relative;
}

.OverviewCategories__reset {
  position: absolute;
  top: 36px;
  right: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: scale 100ms linear;
}

.OverviewCategories__reset-text {
  font-size: 11px;
  font-weight: bold;
  opacity: 0.6;
  color: #363636;
  line-height: 16px;
  margin-left: 4px;
}

.OverviewCategories__reset-icon > .Icon {
  height: 20px;
}

.OverviewCategories__title {
  line-height: 17px;
  padding: 37px 0 13px 0;
  margin-bottom: 4px;
  margin-left: 22px;
  border-bottom: 1px solid #d8d8d8;
  font-size: 14px;
  font-weight: bold;
  color: #363636;
}

.OverviewCategories__buttons {
  overflow: hidden;
  display: flex;
  flex-flow: column;
  gap: 1px;
}

.OverviewCategories__category {
  flex: none;
  color: #363636;
  font-size: 14px;
  height: 32px;
  padding-left: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all 200ms ease-out;
}

.OverviewCategories__category:hover {
  background-color: rgba(5, 117, 230, 0.2);
}

.OverviewCategories__category--selected {
  background-color: rgba(5, 117, 230, 0.2);
  font-weight: 700;
  color: #0575e6;
}

.OverviewCategories__showMore {
  display: flex;
  align-items: center;
  padding-left: 22px;
  margin-top: 8px;
  cursor: pointer;
}

.OverviewCategories__showMore-text {
  font-size: 14px;
  color: #363636;
  margin-left: 8px;
}

.OverviewCategories__showMore-icon {
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
}
