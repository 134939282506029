.ImportUploadDisplay {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.ImportUploadDisplay__title {
  color: #363636;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 13px;
}

.ImportUploadDisplay__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 650px;
}

.ImportUploadDisplayItem {
  width: 150px;
  height: 150px;
  padding: 5px;
  border: 1px solid #d9d9d9;
  margin: 0 5px;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
}

.ImportUploadDisplayItem__image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.ImportUploadDisplayItem__loader {
  position: absolute;
  right: 8px;
  bottom: 8px;
}