.DragAndDropList__Handle,
.DragAndDropList__Delete {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.DragAndDropList__Handle {
  right: 100%;
  padding-right: 10px;
  cursor: grab;
}

.DragAndDropList__Delete {
  left: 100%;
  padding-left: 10px;
  justify-content: flex-end;
  cursor: pointer;
}

.DragAndDropList__Handle .Icon,
.DragAndDropList__Delete .Icon {
  transition: opacity ease-out 200ms;
  color: #363636;
  opacity: 0.5;
}

.DragAndDropList__Handle:hover .Icon,
.DragAndDropList__Delete:hover .Icon {
  opacity: 1;
}
