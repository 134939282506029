.ModalLarge {
  --button-height: 75px;
  --modal-padding: 20px;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(54, 54, 54, 0.7);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalLarge__inner {
  opacity: 1;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 28px 1px rgba(0, 0, 0, 0.2);
  pointer-events: all;
  min-width: 500px;
  display: flex;
  flex-direction: column;
}

.ModalLarge__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid#e6e6e6;
  padding: var(--modal-padding);
}

.ModalLarge__button {
  height: 35px;
  width: auto;
  min-width: 140px;
}

.ModalLarge__button--primary {
  margin-left: auto;
}

.ModalLarge__button--secondary {
}

.ModalLarge__button-text {
  font-size: 18px;
  font-weight: 700;
}
