.Modal {
  --button-height: 65px;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(54, 54, 54, 0.7);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal__inner {
  opacity: 1;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 28px 1px rgba(0, 0, 0, 0.2);
  padding-bottom: var(--button-height);
  pointer-events: all;
}

.Modal__buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
}

.Modal__button {
  flex: 1;
  height: var(--button-height);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Modal__button--primary {
  background-color: #0575e6;
  color: #fff;
}

.Modal__button--secondary {
  background-color: rgba(45, 45, 45, 0.15);
  color: #363636;
}

.Modal__button-text {
  font-size: 18px;
  font-weight: 700;
}
