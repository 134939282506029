.EditFiles {
  --list-border-color: #d8d8d8;
}

.EditFile {
  --file-background: #e9e9e9;

  height: 50px;
  border: 1px solid var(--list-border-color);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  position: relative;
  overflow: hidden; /** Hide overlapping borders */
  color: #737373;
}

.EditFile__Description {
  flex: 1;
}

.EditFile__Description > input {
  border-radius: 5px 0 0 5px;
  border-right: none;
}

.EditFile .TextInput {
  border-color: transparent;
  background-color: transparent;
}

.EditFile__Border {
  border-left: 1px solid var(--list-border-color);
  height: 100%;
}

.EditFile__File {
  flex: none;
  /** Fix elipsis inside a flex container */
  min-width: 0;
  display: flex;
  width: 220px;
  height: 100%;
  background-color: var(--file-background);
}

.EditFile__FileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
}

.EditFile__FileIcon {
  flex: none;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  color: #0575e6;
  margin-left: 17px;
  margin-right: 8px;
}
