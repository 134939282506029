.AddNewPropertyValuePopup {
  --padding: 40px;

  width: 500px;
}

.AddNewPropertyValuePopup__Headline {
  display: flex;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid #d9d9d9;
  color: #363636;
  font-size: 15px;
  font-weight: 700;
  padding-left: var(--padding);
  padding-right: var(--padding);
}

.AddNewPropertyValuePopup__Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;
  padding-left: var(--padding);
  padding-right: var(--padding);
}
