.Overview {
  display: grid;
  grid-template-columns: 1fr 5.5fr;
  grid-template-rows: 100%;
  height: 100%;
}

.Overview__menu {
  flex: 2;
  background-color: #e9e9e9;
  border-right: 1px solid #d8d8d8;
}

.Overview__sync {
  height: 100px;
  border-bottom: 1px solid #d8d8d8;
}

.Overview__clickables {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
}

.Overview__categories {
  width: 100%;
  padding: 0 50px;
}

.Overview__content {
  flex: 5.5;
  background-color: #f2f2f2;
  overflow: scroll;
}

.Overview__category {
  height: 100px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
}

.Overview__status {
  width: 100%;
  padding: 0 50px;
}

.Overview__category-text {
  font-size: 1.1875rem;
  margin-left: 2.1875rem;
  font-weight: bold;
  color: #363636;
}

.Overview__item {
  height: 100px;
  border-bottom: 1px solid #d8d8d8;
  padding: 2.1875rem;
}

.Overview__showMoreItems {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
}

.Overview__showMoreItem--button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding: 20px 0;
  transition: all 300ms linear;
  border-bottom: 1px solid #d8d8d8;
  padding-right: 60px;
}

.Overview__showMoreItem--text {
  font-size: 14px;
}

.Overview__showMoreItem--icon {
  transform: translateY(-1px);
}

.Overview__showMoreItems:hover {
  background-color: rgb(216, 216, 216, 0.4);
  transition: all 300ms linear;
}

.Overview__search {
  position: absolute;
  top: 26px;
  right: 148px;
  display: flex;
  align-items: center;
}

.Overview__no-items-in-search {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.Overview__no-items-in-search-text {
  color: #363636;
  font-size: 15px;
  opacity: 0.7;
}

.scrollbar__styled::-webkit-scrollbar-thumb {
  background-color: #363636;
  opacity: 0.2;
  width: 8px;
}

.scrollbar__styled::-webkit-scrollbar-track {
  background-color: #d8d8d8;
  width: 8px;
}
