.EditImages {
  --list-border-color: #d8d8d8;
}

.EditImage {
  --file-background: #e9e9e9;

  height: 50px;
  border: 1px solid var(--list-border-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  position: relative;
  color: #737373;
  overflow: hidden; /* Cut off corners of image */
}

.EditImage__Description {
  flex: 1.1;
}

.EditImage__Description > input {
  border-radius: 5px 0 0 5px;
  border-right: none;
}

.EditImage .TextInput {
  border-color: transparent;
  background-color: transparent;
}

.EditImage__Border {
  border-left: 1px solid var(--list-border-color);
  height: 100%;
}

.EditImage__Image {
  flex: 1;
  /** Fix elipsis inside a flex container */
  min-width: 0;
  display: flex;
  height: 100%;
  background-color: var(--file-background);
}

.EditImage__FileName {
  padding-left: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
}

.EditImage__ImagePreview {
  flex: none;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 50px;
  background-color: #fff;
}

.EditImage__ImagePreview > img {
  max-height: 40px;
  max-width: 40px;
}
