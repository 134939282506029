.EditCategoryItem {
  display: flex;
  align-items: center;
  height: 62px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
  min-width: 0px;
}

.EditCategoryItem__indicator {
  margin: 0 13px;
}

.EditCategoryItem__indicator-bar {
  border-radius: 2px;
  width: 4px;
  height: 20px;
  background-color: #0575e6;
  opacity: 1;
}

.EditCategoryItem__image {
  height: 40px;
  width: 40px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
}

.EditCategoryItem__image > .Icon {
  max-width: 30px;
  max-height: 30px;
}

.EditCategoryItem__image-img {
  max-width: 30px;
  max-height: 30px;
}

.EditCategoryItem__info {
  margin-left: 11px;
  font-size: 15px;
  min-width: 0px;
}

.EditCategoryItem__info-name {
  font-weight: bold;
  line-height: 18px;
  max-width: 315px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.EditCategoryItem__info-description {
  opacity: 0.6;
  line-height: 17px;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.EditCategoryItem__status {
  margin-left: auto;
  padding-left: 6px;
  padding-right: 20px;
}
