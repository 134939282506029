.Toolbar {
  background-color: #1f232f;
  /* background-color: #11131A; */
  height: 100px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.Toolbar__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
}

.Toolbar__navpath {
  cursor: pointer;
  position: relative;
  height: 100px;
  display: flex;
  isolation: isolate;
}

.Toolbar__home {
  position: relative;
  z-index: 999;
}

.Toolbar__subpath {
  opacity: 0.6;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background-color: #2a3040;
  z-index: 10;
  height: 100px;
  padding: 0 30px 0 50px;
  transform: translateX(-30px);
  display: flex;
  align-items: center;
}

.Toolbar__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
