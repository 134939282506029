.FailedUpload {
  border-bottom: 1px solid #d9d9d9;
  max-width: 622px;
  padding: 4px 0;
  display: flex;
}

.FailedUpload__name {
  padding-left: 8px;
  transform: translateY(1px);
}
