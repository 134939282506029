.Loading {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  position: relative;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

.Loading__spin {
  aspect-ratio: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 68px;
  width: 68px;
}
