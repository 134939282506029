.TextInput {
  font-family: var(--f-family);
  color: #363636;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  transition: border var(--t-hover);
}

.TextInput--loginForm {
  border-bottom: 1px solid rgb(216, 216, 216, 0.3);
  padding: 17px 0;
  line-height: 18px;
  font-size: 1rem;
  color: #fff;
}

.TextInput--loginForm:focus {
  border-bottom: 1px solid rgb(216, 216, 216, 1);
}

.TextInput--loginForm::placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
}

.TextInput--default {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #fff;
  padding: 15px 19px 18px 19px;
  width: calc(100% - 40px);
  font-size: var(--form-font-size);
}

.TextInput--default:not(:disabled):hover {
  border-color: var(--form-color-border-hover);
}

.TextInput--default:not(:disabled):focus-visible {
  border-color: var(--form-color-border-focus);
}

.TextInput[disabled],
.TextInput[disabled]::placeholder {
  background-color: var(--form-color-background-disabled);
  color: var(--form-color-text-disabled);
  cursor: default;
}

/* .TextInput__area {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #fff;
  padding: 15px 19px 18px 19px;
  width: calc(100% - 40px);
  font-size: 15px;
  min-height: 140px;
  vertical-align: top;
  white-space: normal;
  text-overflow: clip;
}

.TextInput__settings {
  border: 1px solid #d8d8d8;
  border-radius: 5px 0 0 5px;
  background-color: #fff;
  padding: 15px 19px 17px 19px;
  width: calc(100% - 40px);
  font-size: 15px;
} */
