.OverviewItem {
  border-bottom: 1px solid #d8d8d8;
  height: 100px;
  padding: 0 35px;
  display: grid;
  grid-template-areas: "image information product-status active-state doneness edit";
  grid-template-columns: 78px minmax(0px, 1fr) 100px 100px 180px 40px;
  gap: 6px;
  align-items: center;
  position: relative;
}

.OverviewItem__image {
  grid-area: image;
  width: 78px;
  height: 78px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OverviewItem__image-img {
  max-width: 60px;
  max-height: 60px;
}

.OverviewItem__information {
  grid-area: information;
  font-size: 15px;
  color: #363636;
}

.OverviewItem__name {
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 18px;
  max-width: 465px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.OverviewItem__description {
  opacity: 0.6;
  line-height: 13px;
  font-size: 14px;
}

.OverviewItem__product-status {
  grid-area: product-status;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OverviewItem__webshop-state {
  grid-area: active-state;
  width: 100%;
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border-radius: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  max-height: 38px;
  cursor: pointer;
  transition: all 200ms ease-out;
}

.OverviewItem__webshop-state-icon {
  margin-left: -5px; /* Optical centering */
}

.OverviewItem__webshop-state:hover {
  background-color: #fff;
  border: 1px solid rgba(54, 54, 54, 0.2);
}

.OverviewItem__webshop-state-text {
  font-size: 11px;
  font-weight: bold;
  color: #363636;
  opacity: 0.6;
}

.OverviewItem__doneness {
  grid-area: doneness;
  padding-right: 10px;
  height: 6px;
}

.OverviewItem__edit {
  grid-area: edit;
  cursor: pointer;
  position: relative;
}

.OverviewItem__edit:hover .OverviewItem__editIconHover {
  opacity: 1;
}

.OverviewItem__editIconHover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 200ms ease-out;
}

@media (max-width: 1025px) {
  .OverviewItem__information {
    max-width: 160px;
  }
}

.OverviewItem__foldout {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.OverviewItem--hasVariants .OverviewItem__foldout {
  display: flex;
}

.OverviewItem__caret {
  transform: rotate(-90deg);
}

.OverviewItem--isOpen .OverviewItem__caret {
  transform: rotate(0deg);
}

.OverviewItem--isVariant {
  padding-left: 60px;
  background: #edecec;
}

/* Vertical bar on variant */
.OverviewItem--isVariant::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px;
  background: #d8d8d8;
}

/* Horizontal bar on variant */

.OverviewItem--isVariant::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 10px;
  height: 1px;
  width: 45px;
  background: #d8d8d8;
}
