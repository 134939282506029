:root {
  --f-family: "Neue Helvetica W01", Arial, Helvetica, sans-serif;

  --t-hover: 200ms ease-out;

  --c-blue: #0575e6;

  --form-font-size: 15px;
  --form-color-text: #363636;
  --form-color-border: #d8d8d8;
  --form-color-border-hover: #aeadad;
  --form-color-background-disabled: #ebebeb;
  --form-color-item-hover: rgba(65, 147, 214, 0.15);
  --form-color-text-disabled: #757575;
  --form-color-border-focus: var(--c-blue);
  --form-padding-horizontal: 19px;
}

.App {
  position: relative;
  width: 100%;
  height: 100vh;
}

.mainRoute {
  position: absolute;
  height: 100%;
  width: 100%;
}

.u-pointer {
  cursor: pointer;
}
