.LoginModal {
  width: 31.5rem;
  height: 34.25rem;
  background-color: #282f43;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 5.3125rem;
}

.LoginModal__header {
  margin-top: 83px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
}

.LoginModal__title {
  color: #fff;
  font-size: 1.75rem;
  margin-top: 0.8125rem;
  font-weight: 600;
}

.LoginModal__sub-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.4);
}

.LoginModal__error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #ff0000;
  animation: wiggle 1s ease-in-out 1;
}

@keyframes wiggle {
  0% {
    transform: translateX(-10px);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translateX(10px);
    animation-timing-function: ease-in-out;
  }
  50% {
    transform: translateX(-10px);
    animation-timing-function: ease-in-out;
  }
  75% {
    transform: translateX(10px);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
}

.LoginModal__pim {
  margin-bottom: 0.25rem;
  line-height: 1.12rem;
  font-weight: bold;
  font-size: 0.9rem;
}

.LoginModal__company {
  opacity: 0.65;
}

.LoginModal__login-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 2rem;
  padding: 0 5.1875rem;
}

.LoginModal__login-button {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
