.Edit {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-template-rows: 100%; /* Make each panel fill 100% of the container */
  height: 100%; /* Make the container fill the screen */
  overflow: hidden;
}

.Edit__toolbar {
  position: fixed;
  top: 0;
  left: 70px;
  background-color: #2a3040;
  z-index: 10;
  height: 100px;
  padding: 0 30px 0 50px;
  display: flex;
  align-items: center;
}

.Edit__toolbar-text {
  opacity: 0.6;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.Edit__category {
  border-right: 1px solid #d8d8d8;
}

.Edit__category > * {
  /* The category box should have full height */
  height: 100%;
}

.Edit__content-wrapper {
  flex: 3.5;
  background-color: #f2f2f2;
  display: grid;
  grid-template-columns: 1fr 260px;
  gap: 2rem;
}

.Edit__content {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 2rem;
  overflow-y: auto;
}

.Edit__content-inner {
  max-width: 550px;
}

.Edit__actions-state,
.Edit__actions-delete {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.Edit__actions-delete {
  margin-top: 5px;
}

.Edit__actions-delete > .Icon {
  width: 40px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Edit__actions-state:hover,
.Edit__actions-delete:hover {
  background-color: #fff;
}

.Edit__actions-stateText,
.Edit__actions-deleteText {
  opacity: 0.6;
  color: #363636;
  font-weight: bold;
  font-size: 12px;
}

.Edit__toggleAdd {
  position: relative;
  height: 88px;
  width: 100%;
}

.Edit__specAdd {
  position: relative;
  height: 50px;
  width: 100%;
}

/* .OverviewItem__webshop-state {
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}

.OverviewItem__webshop-state:hover {
  background-color: #fff;
} */

.Edit__properties table {
  width: 100%;
  font-size: 15px;
  color: #363636;
}

.Edit__properties td {
  border: 1px solid #d8d8d8;
  background-color: #fff;
  padding: 15px 19px 18px 19px;
}

.Edit__properties td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.Edit__properties td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Edit__actions {
  padding-top: 8rem;
  padding-right: 89px;
}

.EditActions__button {
  height: calc(35px + 16px);
  width: 196px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8d8d8;
}

.EditActions__save-icon {
  margin-top: 6px;
}

.EditActions__meta {
  margin-top: 16px;
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8d8d8;
}

.EditActions__savedBy {
  margin-left: 11px;
  color: #363636;
  opacity: 0.6;
  font-size: 12px;
  line-height: 16px;
}

.EditActions__saved-state {
  font-weight: 600;
}

.Edit__hr {
  margin-top: 55px;
  margin-bottom: 45px;
  height: 1px;
  background: #d8d8d8;
}
