.SettingsForm {
	background-color: #F2F2F2;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	padding-bottom: 200px;
	padding-inline: 4rem;
  }
  
  .SettingsForm__content {
	padding-top: 71px;
	max-width: 800px;
  }
  
  .SettingsForm__title {
	display: flex;
	margin-bottom: 11px;
	color: #363636;
	font-size: 15px;
	font-weight: bold;
  }
  
  .SettingsForm__title-static {
	color: #a2a2a2;
  }
  
  .SettingsForm__title-static:hover {
	text-decoration: underline;
	cursor: pointer;
  }
  
  .SettingsForm__form {
	display: flex;
	flex-direction: column;
  }

  .SettingsForm__save {
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
	margin-top: 20px;
  }
  
  .SettingsForm__save-button {
	height: 35px;
	width: 196px;
  }

  .SettingsForm--slim .SettingsForm__content {
	max-width: 500px;
  }