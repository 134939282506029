.OverviewFilterGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 30px;
}

.OverviewFilterGroup__title {
  padding: 0 22px 13px 0;
  margin-bottom: 4px;
  margin-left: 22px;
  border-bottom: 1px solid #d8d8d8;
}

.OverviewFilterGroup__title-text {
  font-size: 14px;
  font-weight: bold;
  color: #363636;
}

.OverviewFilterGroup__reset {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: scale 100ms linear;
}

.OverviewFilterGroup__reset-text {
  font-size: 11px;
  font-weight: bold;
  opacity: 0.6;
  color: #363636;
  line-height: 16px;
  margin-left: 4px;
}

.OverviewFilterGroup__reset-icon > .Icon {
  height: 20px;
}

.OverviewFilterGroup__buttons {
  display: flex;
  flex-flow: column;
  gap: 1px;
}

.OverviewFilterGroup__button {
  color: #363636;
  font-size: 14px;
  height: 32px;
  padding-left: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 16px;
  transition: all 200ms ease-out;
}

.OverviewFilterGroup__button--selected {
  background-color: rgba(5, 117, 230, 0.2);
  font-weight: 700;
  color: #0575e6;
}

.OverviewFilterGroup__button:hover {
  background-color: rgba(5, 117, 230, 0.2);
}
