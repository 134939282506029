.ProductStatus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.ProductStatus__Indicator {
  width: 8px;
  height: 8px;
  content: "";
  background-color: currentColor;
  border-radius: 50%;
}

.ProductStatus__Text {
  font-size: 11px;
  color: #363636;
  opacity: 0.6;
  margin-left: 4px;
}

.ProductStatus--theme-circle .ProductStatus__Text {
  display: none;
}
