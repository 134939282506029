.VariantSelector {
  --item-padding: 40px;
}

.VariantSelector__Modal {
  width: 600px;
}

.VariantSelector__Header {
  display: flex;
  flex-direction: column;
  position: relative;
}

.VariantSelector__Header:after {
  content: "";
  top: 100%;
  right: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  height: 8px;
  /* Create a drop shadow gradient */
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.VariantSelector__Headline {
  display: flex;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid #d9d9d9;
  color: #363636;
  font-size: 15px;
  font-weight: 700;
  padding-left: var(--item-padding);
  padding-right: var(--item-padding);
}

.VariantSelector__Filter {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  position: relative;
}

.VariantSelector__Filter input {
  padding-left: var(--item-padding);
  padding-right: var(--item-padding);
  height: 40px;
  border: 0;
  width: 100%;
  font-size: 15px;
}

.VariantSelector__FilterIcon {
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: var(--item-padding);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity var(--t-hover);
}

.VariantSelector__FilterIcon:hover {
  opacity: 1;
}

.VariantSelector__Variants {
  overflow-y: auto;
  min-height: 250px;
  max-height: min(calc(100svh - 300px), 700px);
}

.VariantSelector__Variant {
  display: flex;
  height: 50px;
  padding-left: var(--item-padding);
  padding-right: var(--item-padding);
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.VariantSelector__Variant:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.VariantSelector__Variant--selected {
  background-color: rgba(0, 0, 0, 0.05);
}

.VariantSelector__VariantCheckbox {
  margin-right: 10px;
}

.VariantSelector__VariantName {
  color: #363636;
  font-size: 15px;
  font-weight: 400;
}

.VariantSelector__VariantDescription {
  color: #363636;
  opacity: 0.5;
  font-size: 12px;
  margin-top: 1px;
}

.VariantSelector__Loading {
  height: 200px;
}
