.EditSpecifications {
  --list-border-color: #d8d8d8;
}

.EditSpecification {
  height: 50px;
  border: 1px solid var(--list-border-color);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  position: relative;
}

.EditSpecification__key {
  flex: 1;
}

.EditSpecification__key > input {
  border-radius: 5px 0 0 5px;
  border-right: none;
}

.EditSpecification .TextInput {
  border-color: transparent;
  background-color: transparent;
}

.EditSpecification__border {
  border-left: 1px solid var(--list-border-color);
  height: 100%;
}

.EditSpecification__value {
  flex: 1.3;
  align-self: center;
}

.EditSpecification--readonly {
  background-color: var(--form-color-background-disabled);
}

.EditSpecification--readonly .TextInput {
  color: #363636;
}
