.VariantGroupNew {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  height: 100%;
  overflow-y: auto;
  padding-inline: 4rem;
  flex-flow: column;
}

.VariantGroupNew__Headline {
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 1.1875rem;
  font-weight: bold;
  color: #363636;
}

.VariantGroupNew__Form {
  width: 100%;
  max-width: 500px;
  padding-bottom: 70px;
}

.VariantGroupNew__Button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.VariantGroupNew__Button > .Button {
  height: 35px;
  width: 196px;
}
