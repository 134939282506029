.SpinAndDone {
  margin-top: 39px;
  display: flex;
}

.SpinAndDone__text {
  line-height: 30px;
  font-size: 15px;
  margin-right: 10px;
  color: #363636;
  font-weight: 600;
}