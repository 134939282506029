.SyncPlan__title {
  margin-bottom: 13px;
}

.SyncPlan__title-text {
  font-size: 15px;
  font-weight: bold;
  color: #363636;
}

.SyncPlan__sync {
  display: flex;
  z-index: 1;
  align-items: center;
}

.SyncPlan__sync > div:first-child > div {
  border-radius: 5px 0px 0px 5px;
}

.SyncPlan__sync > div:nth-child(2) > div {
  border-radius: 0 5px 5px 0;
  border-left: none;
}

.SyncPlan__sync-day {
  width: 220px;
}

.SyncPlan__sync-time {
  width: 294px;
}

.SyncPlan__delete {
  opacity: .5;
 padding: 0 10px;
 cursor: pointer;
}

.SyncPlan__delete:hover {
  transition: opacity 200ms linear;
  opacity: 1;
}