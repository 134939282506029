.SearchBar {
  position: relative;
  height: 48px;
  width: 284px;
  border-radius: 24px;
  border: 1px solid rgba(255,255,255,0.2);
  background-color: rgba(0,0,0,0.7);
  transition: border-color 200ms ease-in-out;
}

.SearchBar:hover {
  border-color: rgba(255,255,255,0.5);
}

.SearchBar__input {
  background-color: transparent;
  height: 98%;
  width: calc(100% - 22px);
  outline: none;
  border: none;
  color: #fff;
  font-size: 15px;
  padding: 0 22px;
}

.SearchBar__input::placeholder {
  vertical-align: middle;
}

.SearchBar:focus-within {
  border-color: rgba(255,255,255,0.5);
}

.SearchBar__icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-40%);
  opacity: .5;
}