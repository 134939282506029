.SyncSettings {
  background-color: #f2f2f2;
  height: 100%;
}

.SyncSettings__content {
  padding-top: 71px;
  padding-inline: 4rem;
}

.SyncSettings__lastsync,
.SyncSettings__typesense {
  max-width: 515px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #ffffff;
  height: 100px;
}

.SyncSettings__poa {
  margin-top: 30px;
  max-width: 553px;
}

.SyncSettings__add-button {
  margin-right: 38px;
}
