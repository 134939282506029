.ImportProductData {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  padding: 71px 124px 0 124px;
}

.ImportProductData__content {
  display: flex;
}

.ImportProductData__title {
  color: #363636;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 13px;
}

.ImportProductData__fileInput {
  flex: 1;
  max-width: 301px;
  background-color: #fff;
  height: 251px;
  border: 1px #d8d8d8;
  border-radius: 5px;
  margin-right: 10px;
}

.ImportProductData__file-name {
  text-align: center;
  font-size: 13px;
  color: #363636;
  margin-top: 10px;
}

.ImportProductData__updateButton {
  flex: 1;
  max-width: 301px;
  color: rgba(54, 54, 54, 0.25);
  height: 251px;
  margin-left: 10px;
  background-color: #fff;
}

.ImportProductData__updateButton--active {
  color: #0575e6 !important;
}

.ImportProductData__fileInput-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 117px;
}

.ImportProductData__fileInput-step {
  height: 134px;
  width: 100%;
  border: 1px solid #0575e6;
  border-radius: 5px 5px 0 0;
  background-color: #0575e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 18px;
}

.ImportProductData__fileInput-step-one {
  opacity: 0.4;
}

.ImportProductData__fileInput-step-text {
  max-width: 179px;
}

.ImportProductData__fileInput-text {
  font-size: 12px;
  color: #363636;
  margin-bottom: 12px;
  margin-top: 13px;
}

.ImportProductData__fileInput-input {
  width: 114px;
  height: 35px;
}

.ImportProductData__updateButton-step {
  background-color: currentColor;
  border-radius: 5px 5px 0 0;
  height: 134px;
  font-weight: 600;
  text-align: center;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImportProductData__updateButton-step-one {
  color: #fff;
  opacity: 0.4;
}

.ImportProductData__updateButton-step-text {
  color: #fff;
  max-width: 170px;
}

.ImportProductData__updateButton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 117px;
}

.ImportProductData__updateButton--button {
  width: 144px;
  height: 35px;
  margin-top: 39px;
}

.ImportProductData__updateButton--button > div {
  background-color: currentColor;
}
