.WYSIWYG .ck-content {
  /* Tweak the ckeditor styles */
  --ck-border-radius: 5px;
  --ck-inner-shadow: none;
  --ck-color-base-border: #d8d8d8;
  --ck-spacing-standard: 19px;
  --ck-focus-ring: 1px solid var(--form-color-border-focus);

  --paragraph-spacing: 8px;
  --list-indent: 1.3em;

  /** Steal some styles from BDJ Webshop */
  font-size: var(--form-font-size);
  line-height: 25px;
  transition: border var(--t-hover);
  color: var(--form-color-text);

  /** Set a minimum height for the box */
  min-height: 200px;
}

.WYSIWYG .ck-content:not(.ck-read-only):hover {
  --ck-color-base-border: var(--form-color-border-hover);
}

.WYSIWYG .ck-content p {
  margin-top: var(--paragraph-spacing);
}

.WYSIWYG .ck-content ul,
.WYSIWYG .ck-content ol {
  margin-top: var(--paragraph-spacing);
}

.WYSIWYG .ck-content li {
  margin-top: 3px;
}

.WYSIWYG .ck-content ul {
  list-style-type: disc;
  padding-left: var(--list-indent);
}

.WYSIWYG .ck-content ol {
  list-style-type: decimal;
  padding-left: var(--list-indent);
}

.WYSIWYG .ck-content.ck-read-only {
  --ck-color-base-background: var(--form-color-background-disabled);
}
