.Select {
  position: relative;
  display: inline-block;
}

.Select__value {
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
}

.Select__options {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 40vh;
  display: none;
  background-color: #fff;
  border: 1px solid #a1b4c4;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
  flex-direction: column;
}

.Select__optionValues {
  overflow-y: auto;
  position: relative; /* This ensures the correct value for offsetTop to scroll to the element */
}

.Select__filter {
  border-bottom: 1px solid #a1b4c4;
}

.Select__filter input {
  border: 0;
  height: 50px;
  padding: 0 var(--form-padding-horizontal);
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  font-size: var(--form-font-size);
}

.Select__element {
  width: 100%;
}

.Select__icon {
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translate(-50%, -55%);
  transition: 250ms transform ease-out 50ms;
  pointer-events: none;
}

.SelectOption {
  cursor: pointer;
  width: auto;
  height: 40px;
}

.Select.is-open .Select__options {
  display: flex;
}

.Select:not(.is-open) .SelectOption {
  border-bottom: 0;
}
.Select:not(.is-open) .SelectOption:hover {
  background-color: transparent;
}

.Select.is-disabled,
.Select.is-disabled .SelectOption {
  cursor: default;
  background-color: var(--form-color-background-disabled);
  color: var(--form-color-text-disabled);
}

/* Default */
.Select--default {
  color: #363636;
  width: 100%;
  /* font-weight: bold; */
  background-color: #fff;
  border: 1px solid var(--form-color-border);
  border-radius: 5px;
  transition: border var(--t-hover);
}

.Select--default:not(.is-disabled):hover {
  border-color: var(--form-color-border-hover);
}

.Select--default .Select__value {
  height: 50px;
  line-height: 48px;
  font-size: var(--form-font-size);
  transition: background-color var(t-hover);
}

.Select--default .Select__value .SelectOption {
  height: 50px;
  line-height: 48px;
}

.Select--default .Select__options {
  /* top: -4px; */
  font-size: 13px;
  border-radius: 5px;
  left: 0px;
  width: 100%;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.Select--default .Select__options:before {
  height: 50px;
}

.Select--default.is-open .Select__options {
  font-weight: normal;
}

.Select--default .SelectOption {
  padding: 0 12px;
  height: 40px;
  line-height: 38px;
  transition: 250ms background-color ease-out;
  border-bottom: 1px solid #d3d7dd;
  font-size: var(--form-font-size);
}

.Select--default .Select__options .SelectOption:not(.is-selected):hover {
  background-color: var(--form-color-item-hover);
}

.Select--default .SelectOption.is-selected {
  font-weight: bold;
  color: #4092d7;
}

/* TYPE: SYNC */
.Select--sync {
  color: #363636;
  width: 100%;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  margin-bottom: 2px;
}

.Select--sync .Select__element {
  display: flex;
  align-items: center;
  height: 50px;
}

.Select--sync .Select__value {
  line-height: 18px;
  font-size: 13px;
  padding: 0 12px;
  transition: 250ms background-color ease-out;
}

.Select--sync .Select__options {
  /* top: -4px; */
  z-index: 1;
  font-size: var(--form-font-size);
  border-radius: 5px;
  left: 0;
  width: 100%;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.Select--sync .Select__options:before {
  height: 50px;
}

.Select--sync.is-open .Select__options {
  font-weight: normal;
  display: flex;
  flex-direction: column;
}

.Select--sync .SelectOption {
  padding: 0 12px;
  height: 50px;
  justify-self: center;
  line-height: 50px;
  background-color: #fff;
  transition: 250ms background-color ease-out;
  border-bottom: 1px solid #d3d7dd;
}

.Select--sync .SelectOption:hover {
  background-color: var(--form-color-item-hover);
}

.Select--sync .SelectOption.is-selected {
  font-weight: bold;
  color: #4092d7;
}
