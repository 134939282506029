.FormElement {
  width: 100%;
}

.FormElement__label {
  margin-top: 20px;
  margin-bottom: 11px;
}

.FormElement__label-text {
  font-weight: bold;
  font-size: 15px;
  color: #363636;
}