.ToolbarActions {
  display: flex;
  align-items: center;
  gap: 24px;
}

.ToolbarActions__settings {
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.ToolbarActions__settings:hover {
  opacity: 1 !important;
}

.ToolbarActions__logout {
  cursor: pointer;
  transition: all 200ms ease-in-out;
  position: relative;
  top: -1px;
}

.ToolbarActions__logout:hover {
  opacity: 1 !important;
}
