.OverviewBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 63px;
  padding-left: 35px;
  border-bottom: 1px solid #d8d8d8;
  transition: 200ms ease-out;
}

.OverviewBtn:hover {
  background-color: #cddae9;
}

.OverviewBtn__Icon {
  margin-right: 11px;
}

.OverviewBtn__Title {
  line-height: 1;
  color: #363636;
  font-size: 13px;
  font-weight: 700;
}
