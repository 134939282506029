.Lightbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Lightbox__Image {
  margin: 20px;
  max-width: 800px;
  max-height: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
