.ListItemNull {
  height: 30px;
  border: 1px solid var(--list-border-color);
  background-color: var(--form-color-background-disabled);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 14px;
  font-size: 12px;
  color: var(--form-color-text-disabled);
}
